import 'npm/jquery/dist/jquery';
import $                from 'jquery';
import {Foundation}     from 'npm/foundation-sites/js/foundation.core';
import * as CoreUtils   from 'npm/foundation-sites/js/foundation.core.utils';
import {Box}            from 'npm/foundation-sites/js/foundation.util.box'
import {onImagesLoaded} from 'npm/foundation-sites/js/foundation.util.imageLoader';
import {Keyboard}       from 'npm/foundation-sites/js/foundation.util.keyboard';
import {MediaQuery}     from 'npm/foundation-sites/js/foundation.util.mediaQuery';
import {Motion, Move}   from 'npm/foundation-sites/js/foundation.util.motion';
import {Nest}           from 'npm/foundation-sites/js/foundation.util.nest';
import {Timer}          from 'npm/foundation-sites/js/foundation.util.timer';
import {Touch}          from 'npm/foundation-sites/js/foundation.util.touch';
import {Triggers}       from 'npm/foundation-sites/js/foundation.util.triggers';
//import { Accordion } from 'npm/foundation-sites/js/foundation.accordion';
//import { AccordionMenu } from 'npm/foundation-sites/js/foundation.accordionMenu';
//import { Drilldown } from 'npm/foundation-sites/js/foundation.drilldown';
//import { Dropdown } from 'npm/foundation-sites/js/foundation.dropdown';
//import { DropdownMenu } from 'npm/foundation-sites/js/foundation.dropdownMenu';
import {Equalizer}      from 'npm/foundation-sites/js/foundation.equalizer';
//import { Interchange } from 'npm/foundation-sites/js/foundation.interchange';
//import { Magellan } from 'npm/foundation-sites/js/foundation.magellan';
import {OffCanvas}      from 'npm/foundation-sites/js/foundation.offcanvas';
//import { Orbit } from 'npm/foundation-sites/js/foundation.orbit';
//import { ResponsiveMenu } from 'npm/foundation-sites/js/foundation.responsiveMenu';
//import { ResponsiveToggle } from 'npm/foundation-sites/js/foundation.responsiveToggle';
// import { Reveal } from 'npm/foundation-sites/js/foundation.reveal';
//import { Slider } from 'npm/foundation-sites/js/foundation.slider';
//import { SmoothScroll } from 'npm/foundation-sites/js/foundation.smoothScroll';
//import { Sticky } from 'npm/foundation-sites/js/foundation.sticky';
//import { Tabs } from 'npm/foundation-sites/js/foundation.tabs';
import {Toggler}        from 'npm/foundation-sites/js/foundation.toggler';
//import { Tooltip } from 'npm/foundation-sites/js/foundation.tooltip';
//import { ResponsiveAccordionTabs } from 'npm/foundation-sites/js/foundation.responsiveAccordionTabs';

Foundation.addToJquery($);

// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.
Foundation.rtl = CoreUtils.rtl;
Foundation.GetYoDigits = CoreUtils.GetYoDigits;
Foundation.transitionend = CoreUtils.transitionend;
Foundation.RegExpEscape = CoreUtils.RegExpEscape;
Foundation.onLoad = CoreUtils.onLoad;

Foundation.Box = Box;
Foundation.onImagesLoaded = onImagesLoaded;
Foundation.Keyboard = Keyboard;
Foundation.MediaQuery = MediaQuery;
Foundation.Motion = Motion;
Foundation.Move = Move;
Foundation.Nest = Nest;
Foundation.Timer = Timer;

// Touch and Triggers previously were almost purely side effect driven,
// so no need to add it to Foundation, just init them.
Touch.init($);
Triggers.init($, Foundation);
MediaQuery._init();

//Foundation.plugin(Abide, 'Abide');
//Foundation.plugin(Accordion, 'Accordion');
//Foundation.plugin(AccordionMenu, 'AccordionMenu');
//Foundation.plugin(Drilldown, 'Drilldown');
//Foundation.plugin(Dropdown, 'Dropdown');
//Foundation.plugin(DropdownMenu, 'DropdownMenu');
Foundation.plugin(Equalizer, 'Equalizer');
//Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
Foundation.plugin(OffCanvas, 'OffCanvas');
//Foundation.plugin(Orbit, 'Orbit');
//Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu');
//Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
//Foundation.plugin(Reveal, 'Reveal');
//Foundation.plugin(Slider, 'Slider');
//Foundation.plugin(SmoothScroll, 'SmoothScroll');
// Foundation.plugin(Sticky, 'Sticky');
//Foundation.plugin(Tabs, 'Tabs');
Foundation.plugin(Toggler, 'Toggler');
//Foundation.plugin(Tooltip, 'Tooltip');
//Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

export {Foundation};

import 'src/js/custom';
import 'npm/lightgallery/dist/js/lightgallery-all.js'
import 'src/scss/bundle.scss';

function importAll(r) {
	r.keys().forEach(r);
}

importAll(require.context('src/images/', true, /\.(png|jpe?g|svg)$/));