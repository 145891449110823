jQuery(document).ready(function () {
	jQuery(document).foundation();

	jQuery(".lgallery").lightGallery({
		selector:  '.cell',
		thumbnail: true,
		share:     false,
		download:  false,
	});

	let url = window.location.href;
	$('ul.menu li a').each(function() {
		if (this.href === url) {
			$(this).closest('li').addClass('active');
		}
	});
});

document.addEventListener("DOMContentLoaded", function() {
	var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
	if ("IntersectionObserver" in window) {
		let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
					let lazyImage = entry.target;
					lazyImage.src = lazyImage.dataset.src;
					// lazyImage.srcset = lazyImage.dataset.srcset;
					lazyImage.classList.remove("lazy");
					lazyImageObserver.unobserve(lazyImage);
				}
			});
		});

		lazyImages.forEach(function(lazyImage) {
			lazyImageObserver.observe(lazyImage);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}
});
